import React from 'react'
import Landing2 from '../components/landing2'
import Layout from '../layouts/siteLayout'

const NotFoundPage = () => (
  <>
    <Layout>
      <Landing2 header="" />
    </Layout>
  </>
)

export default NotFoundPage
